* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
}

h2 {
  font-weight: bold;
  padding: 10px 0;
}

ul {
  padding-left: 20px;
}

// Home

.specials {
  img {
    width: 100%;
  }
}

.carousel-caption {
  color: #fff;
  background: #9bce1a;
  padding: 20px;
  opacity: 0.9;
}

// Navigation styles

nav.navbar.navbar-expand-lg.navbar-light {
  background: #9bce1a;
}

span.navbar-brand {
  font-size: 24px;
}

.headerSocial {
  a {
    color: #fff;
  }
  i.fab,
  i.fas {
    font-size: 24px;
  }
}

// .navbar-light {
//   color: #fff;
// }

.nav-link {
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.reviewer {
  padding: 20px;
  i.fas {
    color: gold;
  }
}

.jumbotron {
  h1 {
    color: #fff;
  }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
  font-family: Arial, sans-serif;
}

.not-found-title {
  font-size: 6em;
  margin: 0;
  color: #343a40;
}

.not-found-message {
  font-size: 1.5em;
  margin: 20px 0;
  color: #6c757d;
}

.not-found-home-link {
  font-size: 1em;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.not-found-home-link:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 750px) {
  .jumbotron {
    height: 650px !important;
  }
}
